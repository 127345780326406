.centered-row-direction {
    display: flex;
    align-items: center;
    justify-content: center;
    direction: row;
}

.start-row-direction {
    display: flex;
    align-items: center;
    justify-content: start;
    direction: row;
}

.centered-column-direction {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.spacebetween-direction {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.end-row-direction {
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: row;
}

.colonna-nomi {
    position: sticky;
    left: 0;
    z-index: 2;
    /* background-color: white;
    border: solid 1px black; */
}

.colonna-nomi-secondColumn {
    position: sticky;
    left: 300px;
    z-index: 2;
    /* background-color: white;
    border: solid 1px black; */
}

.row-head {
    position: sticky;
    top: 0;
    z-index: 3;
    /* background-color: white;
    border: solid 1px black; */
}

.row-head-secondColumn {
    position: sticky;
    top: 0;
    left: 300px;
    z-index:12;
    /* background-color: white;
    border: solid 1px black; */
}

.row-head-secondColumn_mod {
    position: sticky;
    top: 0;
    left: 200px;
    z-index:12;
    /* background-color: white;
    border: solid 1px black; */
}

.secondRow-head {
    position: sticky;
    top: 51px;
    z-index: 3;
}

.disabledCell {
    background-color: #eee;
    color: #00000070 !important
}

.fakeInputBox {
    border: 1px solid #00000047;
    opacity: 1;
    width: 20px;
    text-align: center;
    height: 1.4375em;
    padding: 16.5px 14px;
    border-radius: 4px;
    background-color: rgba(180, 180, 180, 1);
}
.fakeInputBoxFullWidth{
    border: 1px solid #00000047;
        opacity: 1;
        text-align: center;
        height: 1.4375em;
        padding: 16.5px 14px;
        border-radius: 4px;
        background-color: rgba(180, 180, 180, 1);
}
.inputEdit{
    border: 1px solid #00000047;
    border-radius: 5px;
    width: 44px;
    height: 50px;
    animation-duration: 10ms;
    text-align: center;
    font-family: sans-serif;
    font-size: 1.2em;
    margin: 0;
}

/* input:focus{
    border: 2px solid #3f51b5;
} */

.custom-select {
    width: 200px;
    height: 35px;
    padding: 5px;
    border: 1px solid #8bbae8;
    border-radius: 4px;
    background-color: white;
    appearance: none;
  }
  
  .custom-select:focus {
    border: 2px solid #1976d2;
    /* background-color: #f6fafe ; */
  }

  .custom-select.custom-select:focus {
    border: 2px solid #1976d2;
  }

  .custom-select:Hover {
    border: 1px solid #1976d2;
    background-color: #f6fafe ;
  }

  .select-icon {
    height: 2px;
    position: absolute;
    top: 50%;
    right: 10px;
    pointer-events: none;
    transform: translateY(-50%);
  }